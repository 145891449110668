import React, { useState } from 'react';
import { CategoryContext } from './cartContext';

export function CategoryProvider({ children }) {
  const [category, setCategory] = useState("all");
  
  function addToCategory(item) {
    setCategory(item);
  }

  return (
    <CategoryContext.Provider value={{ category, addToCategory }}>
      {children}
    </CategoryContext.Provider>
  );
}
