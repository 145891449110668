import React, { useState } from 'react';
import { ModalContext } from './cartContext';

export function ModalProvider({ children }) {
  const [cartInfo, setCartInfo] = useState([]);
  const [cart, setCart] = useState("");

  function addToModal(item) {
    setCart(item);
  }

  function closeModal(item) {
    setCart(item);
  }

  function modalInfo(item) {
    setCartInfo(item);
  }


  return (
    <ModalContext.Provider value={{ cartInfo, cart, addToModal, closeModal, modalInfo }}>
      {children}
    </ModalContext.Provider>
  );
}
