//COMPONENTS
import Category from "./components/Category";
import Modal from "./components/Modal";
import Products from "./components/Products";
import ShopBasket from "./components/ShopBasket";
import { CartProvider } from './context/cartProvider';
import { ModalProvider } from "./context/modalProvider";
import { CategoryProvider } from "./context/categoryContext";

function App() {
  return (
    <CartProvider>
      <ModalProvider>
        <div className="flex">
          <Modal />
          <ShopBasket />
          <div>
            <CategoryProvider>
              <Products />
              <Category />
            </CategoryProvider>
          </div>
        </div>
      </ModalProvider>
    </CartProvider>
  );
}

export default App;
