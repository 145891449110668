import React, { useContext } from 'react';
//CONTEXT
import { CartContext } from '../context/cartContext';

function ShopButton({ itemId }) {

    const { decrease, increse } = useContext(CartContext);

    const handleShopClick = () => {
        increse(itemId)
    };

    const handleIncreaseClick = () => {
        increse(itemId)
    };

    const handleDecreaseClick = () => {
        decrease(itemId)
    };

    return (
        <div className='flex' >
            <button
                className={itemId.main.quantity > 0 ?
                    "w-1/5 min-w-10 rounded-md bg-red-900 text-white" :
                    ""
                }
                onClick={handleDecreaseClick}>{itemId.main.quantity > 0 ? "-" : ""}</button>

                {itemId.main.quantity > 0 ?<p className='w-3/5 p-1 mx-2 rounded-md text-black bg-white text-center'>{itemId.main.quantity}</p>:   <button
                className={itemId.main.quantity > 0 ?
                    "w-3/5 p-1 mx-2 rounded-md text-black bg-white" :
                    "w-full p-1 rounded-md bg-red-900 text-white"
                }
                onClick={handleShopClick}> افزودن</button>}
            {/* <button
                className={itemId.main.quantity > 0 ?
                    "w-3/5 p-1 mx-2 rounded-md text-black bg-white" :
                    "w-full p-1 rounded-md bg-red-900 text-white"
                }
                onClick={handleShopClick}>{itemId.main.quantity > 0 ? itemId.main.quantity : "افزودن"}</button> */}
            <button
                className={itemId.main.quantity > 0 ?
                    "w-1/5 min-w-10 rounded-md bg-red-900 text-white" :
                    ""
                }
                onClick={handleIncreaseClick}>{itemId.main.quantity > 0 ? "+" : ""}</button>
        </div>
    );
}

export default ShopButton;