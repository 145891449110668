import React, { useEffect, useState,useContext } from "react";
//SERVISES
import { getCategoryInfo } from "../services/api"
//CONTEXT
import { CategoryContext } from '../context/cartContext';

const Category = () => {
    const [data, setData] = useState([]);
    const {addToCategory}=useContext(CategoryContext)

    useEffect(() => {
        const fetchData = async () => {
            setData(await getCategoryInfo())
        }
        fetchData()
    }, []);
    return (
        <ul className="fixed bottom-0 w-[83%] rounded-xl flex justify-around ml-80 bg-gray-400 bg-opacity-20 backdrop-blur-lg drop-shadow-lg">
           
                    <li
                    className="flex py-4 border-b justify-center hover:bg-red-900 hover:rounded-lg cursor-pointer"
                    onClick={() => addToCategory("all")}>همه غذاها</li>
                {data.map((categoryFood, i) => (
                    <li
                        className="flex p-5 border-b justify-center hover:bg-red-900 hover:rounded-lg cursor-pointer"
                        onClick={() => addToCategory(categoryFood.language[1].value)}
                        key={i}>
                        {categoryFood.language[1].value}
                    </li>
                ))}
            </ul>
    )
}
export default Category;