import React, { useState, useEffect, useContext } from "react";
//SERVISES
import { getCategoryInfo, getFoodsInfo } from "../services/api"
//COMPONENTS
import ShopButton from "./ShopButton";
//CONTEXT
import { ModalContext, CategoryContext } from '../context/cartContext';

const Products = () => {
  const { addToModal , modalInfo } = useContext(ModalContext);
  const { category } = useContext(CategoryContext);
  const [data, setData] = useState();
  const [ctg, setCtg] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setData(await getFoodsInfo());
      setCtg(await getCategoryInfo())
    };
    fetchData();
  }, []);

  if (category === "all") {
    const result = []
    ctg.forEach(element => {
      result.push(
        <section key={element.id}>
          <h2 className="flex mx-2 py-2 justify-center rounded-full font-bold bg-gray-400 bg-opacity-20 backdrop-blur-lg drop-shadow-lg">
            {element.language[1].value}
          </h2>
          <div className="flex flex-row flex-wrap gap-6 m-3">
            {data.filter(item => item.category.id === element.id).map((item, index) => {
              const titleObject = item.language.find(lang => lang.key === "title");
              const imageUrl = item.files.length > 0 ? item.main.thumbnail: "";
              const price = item.price;
              const foodId = item.language[0].module_id
              return (
                <div key={index} className="rounded-lg w-[23%] h-1/3 p-1 bg-red-100 space-y-3">
                  <img
                    onClick={() => {
                      addToModal(foodId)
                      modalInfo(item)
                    }}
                    className="rounded-lg w-fit h-80 object-scale-down hover:mix-blend-multiply cursor-pointer"
                    src={imageUrl ?? ""} alt="food" />
                  <div className="flex justify-between">
                    <p>{`تومان${price}`}</p>
                    <p>{titleObject.value}</p>
                  </div>
                  <ShopButton
                    itemId={item}
                  />
                </div>
              )
            })}
          </div>
        </section>)
    })
    return (
      <div className="mx-3 mb-20 pl-80" >
        {result}
      </div>)
  } else {
    const categoryId = ctg.filter(element => element.language[1].value === category).map((item) => item.id)
    return (
      <div className="mx-3 mb-20 pl-80" >
        <section>

          <h2 className="flex mx-2 py-2  justify-center rounded-full font-bold w-[78vw] bg-gray-400 bg-opacity-20 backdrop-blur-lg drop-shadow-lg ">
            {category}
          </h2>
          <div className="flex flex-row flex-wrap gap-4 m-3">
            {data.filter(item => item.category.id === categoryId[0]).map((item, index) => {
              const titleObject = item.language.find(lang => lang.key === "title");
              const imageUrl = item.files.length > 0 ? item.main.thumbnail: "";
              const price = item.price;
              const foodId = item.language[0].module_id
              return (
                <div key={index} className="rounded-lg w-[23%] p-1 bg-red-100 space-y-3">
                  <img
                    onClick={() => {
                      addToModal(foodId)
                      modalInfo(item)
                    }}
                    className="rounded-lg w-fit h-80 object-scale-down hover:mix-blend-multiply cursor-pointer"
                    src={imageUrl ?? ""} alt="food" />
                  <div className="flex justify-between">
                    <p>{`تومان  ${price}`}</p>
                    <p>{titleObject.value}</p>
                  </div>
                  <ShopButton
                    itemId={item} />
                </div>
              )
            })}
          </div>
        </section>
      </div>
    )
  }
};
export default Products;

