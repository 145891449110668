import React from 'react'
//COMPONENTS
import ShopButton from './ShopButton'

export default function BasketCart({ items }) {
  const titleObject = items.language.find(lang => lang.key === "title")
  return (
    <div className='flex flex-col m-2 border-b-[1px]'>
      <div className='flex justify-end'>
        <div>
        <p>{titleObject.value}</p>
        <p className='text-right'>قیمت:{items.price * items.main.quantity}</p>
        </div>
        <img src={`${items.main.thumbnail}`}
          alt='food'
          className='w-1/4 rounded-md ml-3'
        />
      </div>
      <div className='flex justify-between'>
        <ShopButton
          itemId={items}
          />
      </div>
    </div>
  )
}
