import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
//CONTEXT
import { ModalContext } from '../context/cartContext';
import ShopButton from './ShopButton';
//ICONS
import { MdOutlineNavigateNext,MdOutlineNavigateBefore } from "react-icons/md";

const Modal = () => {
    const { cart, closeModal, cartInfo } = useContext(ModalContext);
    const [foodInfo, setFoodInfo] = useState([])
    useEffect(() => {
        if (!cart) {
            return
        } else {
            const fetchData = async () => {
                const res = await axios(`https://foodmenu.metariom.shop/api/food/v1/${cart}`)
                setFoodInfo(res.data.result.data)
            }
            fetchData()
        }
    }, [cart])
    const [cartItems, setCartItems] = useState({});

    const handleItemCountChange = (itemId, count) => {
        setCartItems({
            ...cartItems,
            [itemId]: count,
        });
    };



    const allImage = []
    foodInfo.main && allImage.push(foodInfo.main.thumbnail)
    foodInfo.main && foodInfo.files.forEach(element => {
        allImage.push(element.url)
    })
    const [numImage, setNumImage] = useState(0)
    let image = [allImage[numImage]]

    const pervHandler = () => {
        numImage > 0      ? setNumImage(numImage => numImage - 1) : setNumImage(allImage.length-1)
    }
    const nextHandler = () => {
        if (numImage < allImage.length-1) {
            setNumImage(numImage => numImage + 1)
        } else { setNumImage(0) }
    }

    if (cart) {
        return (
            <div className='z-20 fixed top-0 bottom-0 h-screen w-full flex items-center justify-center backdrop-blur-sm'>
                <div className="relative p-7 bg-stone-200 rounded-2xl border-black border-2">
                    <button
                        className='absolute -top-4 -right-4 '
                        onClick={() => closeModal("")}>
                        <img
                            className='rounded-full'
                            alt="food"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEIklEQVR4nN2aWU8TURTH+yUAwUcwoIgsLWWpgLSUUnZUwER5xU+kieLCUkoLBQF5wEQeLPHNJW6PYGEGKFvpAE3ghb+5k5DQYZY70zsUuckNhLb3/P7ce84950wt+M+HResNJycnODo6giAI4iS/k7+xHicG7agKOD4+Bs/zWFpaSpocx4kGWI3jFOwoCkgkElheXj636Okkr5H3pDoSKdqxGFn07OKHh4emwS9R2LHQLLo2Mweh7yniT/rBT79nshNydlYDIURdHYg627Hin6CyY9FalJ+axUHnYxy0PcJBa6/4kw9MprQTcnZWfEFsWuuxVVKLzTs12CyuxcqbEU07lrOOpAnf0oP95h7st/SCD4QM7QQVfNFdbN52IFrkwMrr4XN2CGuSABKupFFAEd7bjf2mhxC83eDH6LbZEHxhFaI3K7FRWIXIq6FkNp5PFkBClfTMq8J7HkBovI+45wG44CSVCFn4kYA6fEEFNvLtWM+vQMQXTPrsaXgVBZCL4+yLxGG14AV3F+INndhzd4HTOE6pwG/cKMd6ng1rle5kRkFQFhDv66eCj7s6EHe2I+bqUBTBAn49twy8vUFZgPQIkVC53/aICn6vvg1799oQq2/HqoxPMIHPtSIyElA+QrJOHJqG0NJLBb9X14pYbQtida3gJIZ0OWzBefi1PCsiL96qO7FiGA1NI+7tpoOvaUbM4cXuXS+44THT4Jflwqias3ET78RoQwVf7cFuVSN2qj1YHfKbAp+QRDiqVIIbnxKjDRV8pRs79gbsVLhFEWbC60rmSJQh0YYKvtyFbZsTWzanIYf9K3P7Kl2QutJpcmnFnB1U8Ntl97BVWmfaf56qoJHPGCexS8LmJYCnKillRYyFsFPTnHZ4KgHKIiaw7WhKKzy1AEUR/nFsVzamDV6XADJk85rRcWyVu9ICz0aAgTjP51mx4O7Fl2cXKIAl/EdXDyYzijF7rQzfUhRhSRf8TEYJ5jJK8SHbhh/PB81zYjPh5zPLsJBpRTjHjl/Ph9iH0YuA/5RlQzjLhkWDIiyXAT6sIUKtZUOdzBnNKmnhw6eTiHjpM57MsYQncf7rwAhms6108Fn6d0KzoGGRz38f8GE+p5wKPqxThGpJyaoYIfPnwCg+Xa+ggg9riKDqzLGEP52/X40izEAEr9WZI11iVpWUdFeJgxJH1SMinGPHn0EdnTnS4mZVBsr5lRERn4s89J05IoBlDctCxGc1AdIjRB4uRItrmRbgKYnI0ThCsk48PIaNIgfTfF5OxG/i2CoiqJxYKYxGhv1Yv1XFtBjRI2KRNoyqLR4Z8ov9eZaVFI2IRb0XmaoIX1Dsz5MWt7RLzPIh35/BoOisZErPfMqdOTOeFSfMeMx6JR50X4mvGpwd0i9hmDWODNjR1Va5jOMf9zKKGUKUhY4AAAAASUVORK5CYII="></img>
                    </button>
                    <div className='md:flex md:flex-row-reverse md:gap-10 '>

                        <div className='max-w-sm flex relative'>
                            <button
                                onClick={pervHandler}
                                className='absolute top-1/2 left-2 -mt-5 backdrop-blur-lg hover:border hover:border-slate-300 hover:bg-slate-200 rounded-full'>
                                   <MdOutlineNavigateBefore className='text-5xl ' />

                                </button>
                            {image.map((item, index) =>
                                <img
                                    key={index}
                                    src={`${item}`}
                                    alt="food"
                                    className="rounded-lg"
                                />
                            )}
                            <button className='absolute top-1/2 -mt-5  right-2 backdrop-blur-lg hover:border hover:border-slate-300 hover:bg-slate-200 rounded-full '
                                onClick={nextHandler}>
                                    <MdOutlineNavigateNext className='text-5xl'  />
                                </button>
                        </div>

                        <div className='flex max-w-xs flex-col justify-between'>

                            <div >
                                <p className="font-semibold text-right leading-relaxed text-black ">
                                    {foodInfo.main && `${(foodInfo.main.title)}`}
                                </p>
                                <p className="text-base text-right leading-relaxed text-black">
                                    {foodInfo.main && `${(foodInfo.main.description)}`}

                                </p>
                            </div>

                            <div className='flex justify-between'>

                                <div className='w-44'>
                                    <ShopButton
                                        itemId={cartInfo}
                                        onItemCountChange={handleItemCountChange}
                                    />
                                </div>

                                <p><span className='font-medium'>قیمت:</span>{foodInfo.price}</p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Modal;