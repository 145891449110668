import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const getFoodsInfo = async () => {
    const respons = await axios(`${BASE_URL}/api/food/v1`);
    return respons.data.result.data
}
export { getFoodsInfo }

const getCategoryInfo = async () => {
    const respons = await axios(`${BASE_URL}/api/category/v1`)
    return respons.data.result.data
}
export { getCategoryInfo }

const getSingelFoodInfo = async(foodId)=>{
    const respons =await axios(`${BASE_URL}/api/food/v1/${foodId}`)
    return respons.data.result.data
}
export {getSingelFoodInfo}
