import React, { useContext } from "react";
//CONTEXT
import { CartContext } from '../context/cartContext';
//COMPONENTS
import BasketCart from "./BasketCart";

const ShopBasket = () => {
    const { foodInfo } = useContext(CartContext);
    let totalPrice = foodInfo.map(item => item.price * item.main.quantity)
    let sum = 0;

    totalPrice.forEach(function (item) {
        sum += item;
    });

    if (foodInfo.length > 0) {
        return (
            <div className="fixed left-0 w-80 h-full border m-1 rounded-xl overflow-auto pb-44">
                <p className="flex justify-center p-3 rounded-full bg-gray-200 font-medium ">سبد خرید شما</p>
                {foodInfo.map(items => <BasketCart items={items} />)}
                <div className="fixed bottom-0 p-5 w-80 bg-gray-100 bg-opacity-20 backdrop-blur-lg drop-shadow-lg rounded-xl">
                    <div className="flex flex-col  justify-center" >
                        <p className="flex justify-center w-full p-3 font-medium">قیمت کل:{sum}</p>
                        <button className="bg-red-900 rounded-full text-white font-medium p-4 mt-1" >تسویه حساب</button>
                    </div>
                </div>
            </div>

        )
    } else {
        return (
            <div className="fixed left-0 w-80 h-full border m-1 rounded-xl">
                <p className="flex justify-center p-3 rounded-full bg-gray-200 font-medium ">سبد خرید شما</p>
                <div className="mt-32">
                    <div className="flex justify-center">
                        <svg className="flex " width="18" height="20" viewBox="0 0 18 20" fill="gray">
                            <path d="M6.50008 8.33398C6.50008 7.87375 6.12699 7.50065 5.66675 7.50065C5.20651 7.50065 4.83342 7.87375 4.83342 8.33398C4.83342 10.6352 6.6989 12.5006 9.00008 12.5006C11.3013 12.5006 13.1667 10.6352 13.1667 8.33398C13.1667 7.87375 12.7937 7.50065 12.3334 7.50065C11.8732 7.50065 11.5001 7.87375 11.5001 8.33398C11.5001 9.71469 10.3808 10.834 9.00008 10.834C7.61937 10.834 6.50008 9.71469 6.50008 8.33398Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M3.78962 1.11856C3.94786 0.937718 4.17646 0.833984 4.41676 0.833984L14.0001 0.833984C14.2624 0.833984 14.5094 0.957479 14.6668 1.16732L17.1622 4.49457C17.2633 4.62659 17.3257 4.78972 17.3328 4.9671C17.3333 4.98003 17.3335 4.99298 17.3334 5.00593L17.3334 16.6673C17.3334 18.048 16.2141 19.1673 14.8334 19.1673L3.16675 19.1673C1.78604 19.1673 0.666748 18.048 0.666748 16.6673L0.666748 5.00081C0.666748 4.7886 0.746067 4.59476 0.876675 4.44764L3.78962 1.11856ZM13.5834 2.50065L14.8334 4.16732L3.33657 4.16732L4.7949 2.50065L13.5834 2.50065ZM2.33342 5.83398L15.6668 5.83398L15.6668 16.6673C15.6668 17.1276 15.2937 17.5007 14.8334 17.5007L3.16675 17.5007C2.70651 17.5007 2.33342 17.1276 2.33342 16.6673L2.33342 5.83398Z"></path>
                        </svg>
                    </div>
                    <br />
                    <div className="flex justify-center">
                        <p className="text-gray-500 block">
                            !!! سبد خرید خالی است
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default ShopBasket;