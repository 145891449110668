import React, { useState } from 'react';
import { CartContext } from './cartContext';

export function CartProvider({ children }) {
  const [foodInfo, setFoodInfo] = useState([])

  function increse(item) {
    if (!!foodInfo.find(element => item.main.food_id === element.food_id)) {
      setFoodInfo([...foodInfo]);
      item.main.quantity++
    } else {
      setFoodInfo([...foodInfo, item]);
      item.main.quantity++
    }
  }
  function decrease(item) {
    setFoodInfo([...foodInfo]);
    item.main.quantity--
    item.main.quantity === 0 && setFoodInfo((pervFood) => pervFood.filter(i => i !== item))
  }
  return (
    <CartContext.Provider value={{ foodInfo, increse, decrease }}>
      {children}
    </CartContext.Provider>
  );
}
